<template>
<div>
  <div v-if="$debug.isOn">
    <debug-obj label="form" :objData="form"/>
    <debug-obj label="event" :objData="event"/>
  </div>
  <h1>Create a {{ eventName }}</h1>
  <p class="text-muted" v-if="config.desc">{{ config.desc }}</p>
  <!-- Create Event -->
  <div v-if="!event && (!$route.query.createNow || errorMsg)" class="sub-mt-2">
    <div>
      <label>Title:</label>
      <b-form-input v-model="form.title" autocomplete="off" :placeholder="`${user.name}'s ${eventName}`"/>
    </div>
    <div>
      <label>Description:</label>
      <b-form-textarea v-model="form.description"/>
    </div>
    <b-form-checkbox v-model="form.scheduled">Scheduled</b-form-checkbox>
    <div class="form-inline sub-mt-2 sub-mr-2" v-if="form.scheduled">
      <label>Start date:</label>
      <b-form-datepicker id="dp" v-model="form.date" reset-button/>
      <b-form-timepicker id="stp" v-model="form.startTime"/>
      <b-form-timepicker id="etp" v-model="form.endTime"/>
    </div>
    <div class="form-inline sub-mt-2 sub-mr-2" v-if="config.showTemplate">
      <label>Template:</label>
      <b-form-select v-model="form.composerTemplate" :options="templateOptions"/>
      <b-button class="btn btn-secondary" @click="showPreview">Preview</b-button>
      <button class="btn btn-secondary">Customize</button>
    </div>
    <div v-if="true">
      <label>URL:</label>
      <div class="form-inline sub-mr-2">
        <div>{{ $app.appURL }}/guest/join/</div><b-form-input size="sm" v-model="form.shortName"/>
      </div>
    </div>
    <b-alert class="mt-4" variant="danger" :show="errorMsg != null">
      {{ errorMsg }}
    </b-alert>
    <button class="mt-3 btn btn-primary" @click="create">Create</button>
  </div>
  <!-- Preview Modal -->
  <b-modal id="modal-preview" centered title="Preview">
    <composer ref="composer" class="w-100" :template="template" :layout-id="layoutSelect"/>
    <button class="btn btn-primary mr-2" @click="layoutSelect = id" v-for="id in layoutOptions" :key="id">{{ id }}</button>
  </b-modal>
  <!-- Share Event -->
  <div v-if="event">
    <b-alert variant="success" :show=true>
      Congratulations! Now invite your guests speakers and share the {{ eventName }} for the World to attend!
    </b-alert>
    <div class="mt-4">
      <button class="btn btn-primary" @click="$router.push('/guest/event/' + event.id)">Join {{ eventName }}</button>
    </div>
    <div class="mt-4">
      Invite Guest Speakers to join {{ eventName }}:
      <b-form-input readonly :value="guestURL"/>
      <button class="btn btn-primary mt-2" @click="share(guestURL, 'I\'m inviting you to speak on my show')" v-if="canShare">Share</button>
    </div>
    <div class="mt-4">
      {{ eventName }} URL:
      <b-form-input readonly v-model="showURL"/>
      <button class="btn btn-primary mt-2" @click="share(showURL, 'Come check the show')" v-if="canShare">Share</button>
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('events-form');
import { db, timestampFromDate } from "@/services/db";
import { inheritedObj } from "@/services/dbutils";
import { createEventFromTemplate } from "@/services/events";
import { registerUserToEvent } from "@/services/user";

export default {
  components: { 
    Composer: () => ({
      component: import("@/components/composer.vue"),
      loading: import("@/components/loading.vue")
    }),
  },
  props: {
    user: Object,
    config: {
      type: Object,
      default: () => { return {}; },
    }
  },
  data() {
    return {
      templates: [],
      template: null,
      layoutSelect: "fullA",

      form: {
        shortName: "",
        title: "",
        description: "",
        date: new Date().toISOString().split("T")[0],
        startTime: "19:00:00",
        endTime: "20:00:00",
        composerTemplate: "chromeless",
      },

      event: null,
      errorMsg: null,
    };
  },
  computed: {
    guestURL() {
      return this.$app.appURL + '/guest/join/' + this.event.id + '?speaker=true';
    },
    showURL() {
      return this.$app.appURL + '/guest/join/' + this.event.id;
    },
    canShare() {
      return navigator.share;
    },
    templateOptions() {
      return this.templates.map((t) => t.id);
    },
    layoutOptions() {
      if (!this.template)
        return [];
      return Object.keys(this.template.layouts).sort((a, b) => a.localeCompare(b));
    },
    eventName() {
      return this.config?.name || this.$app.eventName;
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      log.log("init", this.config, this.$route.query);
      await this.$bind("templates", db.collection("LiveStudioTemplates").where("display", "==", true));
      if (this.$route.query.createNow)
        this.create(true);
    },
    async showPreview() {
      this.template = await inheritedObj(db.collection("LiveStudioTemplates"), this.form.composerTemplate);
      log.log("showPreview", this.form.composerTemplate, this.template);
      this.$bvModal.show('modal-preview');
    },
    async create(now) {
      log.log("create");
      if (!this.user.canCreateEvents) {
        this.errorMsg = "Your account is not able to create events.";
        return;
      }
      this.form.title = this.form.title || `${this.user.name}'s ${this.eventName}`;
      let data = Object.assign({
        template: this.config.template || "default_stage",
      }, this.form);
      if (now) {
        let now = new Date();
        data.startDate = timestampFromDate(new Date());
        now.setHours(now.getHours() + 2);
        data.endDate = timestampFromDate(now);
      } else {       
        log.log("data.date", data.date);
        log.log("data.startTime", data.startTime);
        data.startDate = timestampFromDate(new Date(Date.parse(data.date + "T" + data.startTime)));
        log.log("data.startDate", data.startDate);
        if (data.scheduled) {
          data.endDate = timestampFromDate(new Date(Date.parse(data.date + "T" + data.endTime)));
          log.log("data.endDate", data.endDate);
        }
      }
      delete data.date;
      delete data.startTime;
      let userId = this.user.id;
      data.userIds = [userId];
      data.userRoles = {[userId]: this.config.creatorRole || "host"};
      let eventId = data.shortName || null;
      this.event = await createEventFromTemplate(eventId, data.template, data);
      if (!this.event)
        this.errorMsg = `Error: Could not create ${this.eventName}`;
      else
        registerUserToEvent(this.user, this.event.id);
    },
    share(url, text) {
      if (navigator.share)
        navigator.share({
          title: this.form.description,
          text,
          url,
        })
    }
  }
}
</script>
