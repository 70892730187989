<template>
  <!-- Form -->
  <event-form
    :config="eventType"
    :user="$parent.user"/>  
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('events-create-type');
import EventForm from '@/pages/eventForm.vue';
import { eventTypes } from '@/services/event-manager';

export default {
  components: { 
    EventForm,
  },
  data() {
    return {
      eventType: {},
    };
  },
  mounted() {
    this.eventType = eventTypes.find((et) => et.type == this.$route.params.eventType);
    log.log("event", this.$route.params.eventType, this.eventType);
  }
}
</script>

<style>

</style>